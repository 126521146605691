export class PxFile {
  private readonly urlInstance: string;

  get size(): number {
    return this.rawFile.size;
  }
  get name(): string {
    return this.rawFile.name;
  }
  get extension(): string {
    return PxFile.getRawFileExtension(this.rawFile);
  }
  get url(): string {
    return this.urlInstance;
  }
  get type(): string {
    return this.rawFile.type;
  }

  constructor(
    readonly rawFile: File,
    url: string,
    readonly fullPath: string | null = null
  ) {
    this.urlInstance = url;
  }

  static getRawFileExtension(rawFile: File): string {
    return rawFile.name.substring(rawFile.name.lastIndexOf('.'));
  }

  getRawFile(): File & {fullPath: string | null} {
    return Object.assign(this.rawFile, {fullPath: this.fullPath});
  }

  getFolderName(): string {
    const pathParts: string[] = (this.fullPath ?? '').split('/');

    if (pathParts.length < 2) {
      return '';
    }

    return pathParts[pathParts.length - 2];
  }
}
