import {inject, Injectable} from '@angular/core';
import {filter, map, Observable} from 'rxjs';
import {Account, IAccount, IAccountInput, IAccountSubscriptionInfo, IBillingInfo, PublicAccount} from '../entities';
import {ILogoUploadParameters, LogoUploadParameters} from '../entities/logo-upload-parameters.interface';
import {Apollo, gql} from 'apollo-angular';
import {ProductFamily} from '@px/shared/data-access/product-product-family';
import {plainToInstance} from 'class-transformer';

@Injectable()
export class AccountDataService {
  private readonly apollo = inject(Apollo);

  private readonly myAccountQuery = gql<{account: IAccount}, void>`
    query myAccount {
      account: myAccount {
        pxId
        email
        isActive
        profile {
          firstName
          lastName
          studioName
          websiteUrl
          metaData
        }
      }
    }
  `;

  private readonly myAccountWithSubscriptionInfoQuery = gql<
    {account: IAccountSubscriptionInfo} & Record<ProductFamily, IBillingInfo[]>,
    {status: string}
  >`
    query myAccount($status: BillingSubscriptionStatus) {
      account: myAccount {
        pxId
        email
        isActive
        dateCreated
        profile {
          firstName
          lastName
          studioName
          websiteUrl
          metaData
        }
      }
      SA: customerSubscriptions(productFamily: SA, status: $status) {
        ...customerSubscription
      }
      PSS: customerSubscriptions(productFamily: PSS, status: $status) {
        ...customerSubscription
      }
      PSF: customerSubscriptions(productFamily: PSF, status: $status) {
        ...customerSubscription
      }
      BUNDLE: customerSubscriptions(productFamily: BUNDLE, status: $status) {
        ...customerSubscription
      }
      CP: customerSubscriptions(productFamily: CP, status: $status) {
        ...customerSubscription
      }
    }
    fragment customerSubscription on BillingSubscription {
      status
      package {
        products {
          price {
            billingProductFamily {
              productFamily
            }
            product {
              id
              name
            }
          }
        }
      }
    }
  `;

  private readonly publicAccountQuery = gql<{publicAccount: IAccount}, {pxId: number}>`
    query PublicAccount($pxId: PxId!) {
      publicAccount(pxId: $pxId) {
        pxId
        socialLinks {
          facebook
          instagram
          pinterest
          tiktok
          twitter
          contactEmail
        }
        contactInfo {
          isAllRightReservedPresent
          isRightPlaceholderEnabled
          isVCardAttachedToEmail
          isVCardEnabled
          address
          address2
          avatarUrl
          companyName
          copyRightPlaceholder
          country
          email {
            value
            label
          }
          firstName
          jobTitle
          lastName
          note
          phone {
            value
            label
          }
          postalCode
          state
          website {
            value
            label
          }
        }
      }
    }
  `;

  private readonly updateAccountMutation = gql<
    {updateAccount: IAccount},
    {accountInput: Partial<IAccountInput>; email: string; pxId: number}
  >`
    mutation UpdateAccount($accountInput: AccountInput!) {
      updateAccount(accountInput: $accountInput) {
        pxId
        pxr
        email
        profile {
          firstName
          lastName
          studioName
          websiteUrl
          metaData
        }
      }
    }
  `;

  private readonly logoUploadParametersQuery = gql<{logoUploadParameters: ILogoUploadParameters}, {fileName: string}>`
    query LogoUploadParameters($fileName: String!) {
      logoUploadParameters(fileName: $fileName) {
        url
        fields
        urlAfterUpload
      }
    }
  `;

  getMyAccount(): Observable<Account> {
    return this.apollo
      .query({
        query: this.myAccountQuery,
      })
      .pipe(
        filter(result => !!result.data),
        map(result => plainToInstance(Account, result.data?.account))
      );
  }

  getMyAccountWithSubscriptionInfo(): Observable<Account> {
    return this.apollo
      .query({
        query: this.myAccountWithSubscriptionInfoQuery,
        variables: {
          status: 'ACTIVE',
        },
      })
      .pipe(
        filter(result => !!result.data),
        map(result => {
          const account = plainToInstance(Account, result.data?.account);
          account.setSubscriptions(
            result.data?.PSF ?? [],
            result.data?.PSS ?? [],
            result.data?.SA ?? [],
            result.data?.BUNDLE ?? [],
            result.data?.CP ?? []
          );

          return account;
        })
      );
  }

  getPublicAccount(pxId: number): Observable<PublicAccount> {
    return this.apollo
      .query({
        query: this.publicAccountQuery,
        variables: {
          pxId,
        },
        fetchPolicy: 'network-only',
      })
      .pipe(
        filter(result => !!result.data),
        map(result => plainToInstance(PublicAccount, result.data?.publicAccount))
      );
  }

  updateAccount(accountInput: Partial<IAccountInput>, email: string, pxId: number): Observable<Account> {
    return this.apollo
      .mutate({
        mutation: this.updateAccountMutation,
        variables: {
          accountInput,
          email,
          pxId,
        },
        refetchQueries: [
          {
            query: this.myAccountQuery,
          },
        ],
      })
      .pipe(
        filter(result => !!result.data),
        map(result => plainToInstance(Account, result.data?.updateAccount))
      );
  }

  logoUploadParameters(fileName: string): Observable<LogoUploadParameters> {
    return this.apollo
      .query({
        query: this.logoUploadParametersQuery,
        variables: {
          fileName,
        },
        fetchPolicy: 'network-only',
      })
      .pipe(
        filter(result => !!result.data),
        map(result => plainToInstance(LogoUploadParameters, result.data?.logoUploadParameters))
      );
  }

  invalidateMyAccount(): void {
    this.apollo.client.cache.evict({id: 'ROOT_QUERY', fieldName: 'myAccount'});
    this.apollo.client.cache.gc();
  }
}
