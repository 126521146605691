import {PxFile} from './px-file';
import {FileConstructor} from './file-constructor';
import {FileValidatorFunction} from './file-validator-function';

export abstract class FileValidators {
  static fileType<T>(availableType: FileConstructor, errorValue: T, availableExtensions?: string[]) {
    return (file: PxFile): T | null => {
      return file instanceof availableType &&
        (availableExtensions ? availableExtensions.some(e => file.extension.toLowerCase() === e.toLowerCase()) : true)
        ? null
        : errorValue;
    };
  }
  static fileSize<T>(maxSizeB: number, errorValue: T) {
    return (file: PxFile): T | null => {
      return file.size > maxSizeB ? errorValue : null;
    };
  }

  static uniq<T>(errorValue: T): FileValidatorFunction<T, string[]> {
    return (file: PxFile, payload?: string[]): T | null => {
      return payload?.includes(file.name) ? errorValue : null;
    };
  }
}
