import {NgClass, NgIf, NgTemplateOutlet} from '@angular/common';
import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  forwardRef,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import {SafeUrl} from '@angular/platform-browser';
import {CAN_DISABLED} from '@pui/cdk/can-disabled';
import {ButtonCore} from '../core/core';

@Component({
  selector: 'mds-button',
  standalone: true,
  imports: [NgIf, NgClass, NgTemplateOutlet],
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{provide: CAN_DISABLED, useExisting: forwardRef(() => MdsButtonComponent)}],
})
export class MdsButtonComponent extends ButtonCore implements OnInit {
  private readonly elementRef = inject(ElementRef);

  hasTransitions = false;

  @Input() size?: 'sm' | 'md' | 'lg' | 'xl' = 'md';
  @Input() type?: 'text' | 'outlined' | 'filled' = 'text';
  @Input() buttonType?: 'button' | 'submit' | 'reset' = 'submit';
  @Input() variant?: 'fab' | 'fab-circle' | 'base' = 'base';
  @Input() color?: 'primary' | 'neutral' | 'dark' | 'white' | 'secondary' = 'primary';
  @Input() href?: string | SafeUrl;
  @Input() target?: '_self' | '_blank' | '_parent' | '_top' = '_self';
  @Input() iconPosition?: 'left' | 'right';
  @Input() minWidth?: string = 'none';
  @Input({transform: booleanAttribute}) focused = false;

  get nativeElement(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  get hostClass(): string[] {
    const hostClass: string[] = [];

    if (this.disabled) {
      hostClass.push('c-button--disabled');
    }

    if (this.readonly) {
      hostClass.push('c-button--readonly');
    }

    if (this.type) {
      hostClass.push(`c-button--type-${this.type}`);
    }

    if (this.size) {
      hostClass.push(`c-button--size-${this.size}`);
    }

    if (this.variant) {
      hostClass.push(`c-button--variant-${this.variant}`);
    }

    if (this.color) {
      hostClass.push(`c-button--color-${this.color}`);
    }

    if (this.hasTransitions) {
      hostClass.push('has-transitions');
    }

    if (this.iconPosition) {
      hostClass.push('c-button--icon-' + this.iconPosition);
    }

    if (this.hasProgress) {
      hostClass.push('c-button--progress');
    }

    if (this.focused) {
      hostClass.push('c-button--state-hover');
    }

    return hostClass;
  }

  ngOnInit(): void {
    this.hasTransitions = true;
  }
}
